import axios from "axios";

export const isOffensiveName = async (username) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/check-username-offensive`,
      { username }
    );
    if (response.status === 200) {
      return response.data.available;
    }
  } catch (e) {
    return false;
  }
};

export const userAlreadyRegistered = async (username) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/check-username-availability`,
      { username }
    );
    if (response.status === 200) {
      return response.data.available;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const emailAlreadyRegistered = async (email) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/check-email-availability`,
      { email }
    );
    // If we got a 200, we can rely on the returned JSON
    return response.data.available;
  } catch (e) {
    // If the error has a response with data, optionally log or process it.
    if (e.response) {
      console.error("Error response:", e.response.data);
    }
    // Return false so the Yup test fails gracefully.
    return false;
  }
};
