import * as Yup from "yup";
import { differenceInYears } from "date-fns";
import {
  emailAlreadyRegistered,
  isOffensiveName,
  userAlreadyRegistered,
} from "./validationRequest";

const emailOrUserNameValidation = Yup.string()
  .test(
    "is-username-or-email",
    "Must be a valid username or email.",
    function (value) {
      const { path, createError } = this;
      const usernameRegex = /^[a-zA-Z0-9_]+$/;

      const isEmail = Yup.string().email().isValidSync(value);
      const isUsername = usernameRegex.test(value);
      if (
        !isEmail &&
        isUsername &&
        !(value.length >= 3 && value.length <= 32)
      ) {
        return createError({
          path,
          message: "Username must be 3-32 characters long.",
        });
      }
      if (!isEmail && !isUsername) {
        return createError({
          path,
          message: "Must be a valid username or email.",
        });
      }
      return true;
    }
  )
  .required("This field is required");
const passwordValidation = Yup.string()
  .min(8, "Password must be at least 8 characters long.")
  .matches(/[a-zA-Z]/, "Password must contain at least one letter.")
  .matches(/[0-9]/, "Password must contain at least one number.")
  .matches(
    /[!@#$%^&*]/,
    "Password must contain at least one special character."
  )
  .required("Password is required.");
const LoginSchema = Yup.object().shape({
  emailOrUserName: emailOrUserNameValidation,
  password: passwordValidation,
});

const RegisterSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z]+$/, "First name must contain only letters.") // Restrict to alphabetic characters only
    .required("First Name is required."),
  lastName: Yup.string()
    .matches(/^[A-Za-z]+$/, "Last name must contain only letters.") // Restrict to alphabetic characters only
    .required("Last Name is required."),
  email: Yup.string()
    .required("Email is required.")
    .email("Email must be a valid email address.")
    .test("is-available", "This email is already taken.", async (email) => {
      if (!email) return false; // Return false if value is empty
      return await emailAlreadyRegistered(email);
    }),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long.")
    .matches(/[a-zA-Z]/, "Password must contain at least one letter.")
    .matches(/[0-9]/, "Password must contain at least one number.")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character."
    )
    .required("Password is required."),
  confirmPassword: Yup.string()
    .required("Confirm password is required.")
    .oneOf([Yup.ref("password"), null], "Passwords must match."),
  gender: Yup.string().required("Gender must be selected."),
  terms: Yup.boolean()
    .oneOf([true], "The terms and conditions must be accepted.")
    .required("The terms and conditions must be accepted."),
  isAdult: Yup.boolean()
    .oneOf([true], "The consent must be accepted.")
    .required("The consent must be accepted."),
  dob: Yup.date()
    .nullable()
    .required("Date of birth is required.")
    .transform(function (value, originalValue) {
      const parsedDate = new Date(originalValue);
      return isNaN(parsedDate) ? new Date("") : parsedDate; // Return Invalid Date for wrong formats
    })
    .typeError("Please enter a valid date in the format MM/DD/YYYY.")
    .test("age", "You must be at least 13 years old.", function (value) {
      return differenceInYears(new Date(), value) >= 13;
    }),
  phone: Yup.string()
    .matches(/^\+(?:[0-9] ?){8,14}[0-9]$/, "Phone number is not valid.")
    .required("Phone number is required."),
  username: Yup.string()
    .trim()
    .lowercase()
    .min(
      3,
      "Username must be 3-32 characters long and contain only alphanumeric characters."
    )
    .max(
      32,
      "Username must be 3-32 characters long and contain only alphanumeric characters."
    )
    .matches(
      /^[a-zA-Z0-9]+$/,
      "Username must contain only alphanumeric characters."
    )
    .test(
      "not-offensive",
      "This username is not allowed.",
      async (username) => {
        if (!username) return false;
        return !(await isOffensiveName(username));
      }
    )
    .test(
      "is-available",
      "This username is already taken.",
      async (username) => {
        if (!username) return false; // Return false if value is empty
        return await userAlreadyRegistered(username);
      }
    ),
});

const RegisterViaInviteSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z]+$/, "First name must contain only letters.") // Restrict to alphabetic characters only
    .required("First Name is required."),
  lastName: Yup.string()
    .matches(/^[A-Za-z]+$/, "Last name must contain only letters.") // Restrict to alphabetic characters only
    .required("Last Name is required."),
  email: Yup.string()
    .required("Email is required.")
    .email("Email must be a valid email address."),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long.")
    .matches(/[a-zA-Z]/, "Password must contain at least one letter.")
    .matches(/[0-9]/, "Password must contain at least one number.")
    .matches(
      /[!@#$%^&*]/,
      "Password must contain at least one special character."
    )
    .required("Password is required."),
  confirmPassword: Yup.string()
    .required("Confirm password is required.")
    .oneOf([Yup.ref("password"), null], "Passwords must match."),
  gender: Yup.string().required("Gender must be selected."),
  terms: Yup.boolean()
    .oneOf([true], "The terms and conditions must be accepted.")
    .required("The terms and conditions must be accepted."),
  isAdult: Yup.boolean()
    .oneOf([true], "The consent must be accepted.")
    .required("The consent must be accepted."),
  dob: Yup.date()
    .nullable()
    .required("Date of birth is required.")
    .transform(function (value, originalValue) {
      const parsedDate = new Date(originalValue);
      return isNaN(parsedDate) ? new Date("") : parsedDate; // Return Invalid Date for wrong formats
    })
    .typeError("Please enter a valid date in the format MM/DD/YYYY.")
    .test("age", "You must be at least 13 years old.", function (value) {
      return differenceInYears(new Date(), value) >= 13;
    }),
  phone: Yup.string()
    .matches(/^\+(?:[0-9] ?){8,14}[0-9]$/, "Phone number is not valid.")
    .required("Phone number is required."),
  username: Yup.string()
    .trim()
    .lowercase()
    .min(
      3,
      "Username must be 3-32 characters long and contain only alphanumeric characters."
    )
    .max(
      32,
      "Username must be 3-32 characters long and contain only alphanumeric characters."
    )
    .matches(
      /^[a-zA-Z0-9]+$/,
      "Username must contain only alphanumeric characters."
    )
    .test(
      "not-offensive",
      "This username is not allowed.",
      async (username) => {
        if (!username) return false;
        return !(await isOffensiveName(username));
      }
    )
    .test(
      "is-available",
      "This username is already taken.",
      async (username) => {
        if (!username) return false; // Return false if value is empty
        return await userAlreadyRegistered(username);
      }
    ),
});

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const containsKeyword = (keyword) => {
  return Yup.string()
    .url(`${capitalizeFirstLetter(keyword)} must be a valid URL.`)
    .nullable()
    .test(
      `${keyword}-keyword`,
      `${keyword} URL must contain ${keyword}.`,
      (value) => {
        if (value) {
          return value.includes(keyword);
        }
        return true; // If the field is empty, it's considered valid
      }
    );
};

const ManagePlayerValidationSchema = Yup.object().shape({
  privateNotes: Yup.string(),
  games: Yup.array()
    .of(
      Yup.object().shape({
        game: Yup.string(),
        gamerId: Yup.string(),
        positions: Yup.array().of(Yup.string()),
      })
    )
    .test(
      "unique-game",
      "Each game should be unique - only 1 entry per game is allowed.",
      (list) => {
        const games = list.map((item) => item.name).filter((game) => game);
        return games.length === new Set(games).size;
      }
    ),
});

const PlayerAccountValidationSchema = Yup.object().shape({
  city: Yup.string(),
  state: Yup.string(),
  country: Yup.string(),
  zipcode: Yup.string().test(
    "is-valid-zipcode",
    "Invalid ZIP code",
    function (value) {
      // If the value is empty, consider it valid
      if (!value) {
        return true;
      }
      // Check if the value matches the ZIP code format
      return /^\d{5}(-\d{4})?$/.test(value);
    }
  ),
  games: Yup.array()
    .of(
      Yup.object().shape({
        game: Yup.string(),
        gamerId: Yup.string(),
        positions: Yup.array().of(Yup.string()),
      })
    )
    .test(
      "unique-game",
      "Each game should be unique - only 1 entry per game is allowed.",
      (list) => {
        const games = list.map((item) => item.name).filter((game) => game);
        return games.length === new Set(games).size;
      }
    ),
  facebook: containsKeyword("facebook"),
  twitter: containsKeyword("x"),
  instagram: containsKeyword("instagram"),
  threads: containsKeyword("threads"),
});

const OrganizationAccountValidationSchema = Yup.object().shape({
  photoUrl: Yup.string(),
  officialRepresentativeCheckbox: Yup.boolean()
    .oneOf([true], "You must accept the terms and conditions.")
    .required("This field is required"),
  name: Yup.string(),
  announcement: Yup.string(),
  locationCity: Yup.string(),
  locationState: Yup.string(),
  locationCountryCode: Yup.string(),
  locationZipCode: Yup.string().test(
    "is-valid-zipcode",
    "Invalid ZIP code",
    function (value) {
      // If the value is empty, consider it valid
      if (!value) {
        return true;
      }
      // Check if the value matches the ZIP code format
      return /^\d{5}(-\d{4})?$/.test(value);
    }
  ),
  schoolDescription: Yup.string(),
  email: Yup.string().email("Email must be a valid email address."),
  phone: Yup.string()
    .notRequired()
    .test("is-valid-phone", "Phone number is not valid.", (value) => {
      if (!value) {
        return true; // Skip regex validation if the phone number is empty
      }
      return /^\+(?:[0-9] ?){8,14}[0-9]$/.test(value);
    }),
  website: Yup.string().url("Website must be a valid URL."),
  discord: Yup.string(),
  videoUpload: Yup.boolean(),
  youtubeLink: Yup.string().when("videoUpload", {
    is: false,
    then: Yup.string()
      .url("Video must be a valid URL.")
      .matches(
        /^$|^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(?:-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|live\/|v\/)?)([\w-]+)(\S+)?$/,
        "Video must be a valid YouTube URL."
      ),
    otherwise: Yup.string().nullable(),
  }),
  socials: Yup.object().shape({
    instagram: containsKeyword("instagram"),
    twitter: containsKeyword("x"),
    facebook: containsKeyword("facebook"),
    threads: containsKeyword("threads"),
  }),
  totalNumberOfTeamsInOrganization: Yup.number()
    .typeError("Must be a valid number")
    .integer("Total number of teams in organization must be an integer.")
    .required("Total number of teams in organization is required."),
  totalNumberOfPlayersInOrganization: Yup.number()
    .typeError("Must be a valid number")
    .integer("Total number of players in organization must be an integer.")
    .required("Total number of players in organization is required."),
  gamesPlayedByTeam: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
    })
  ),
  academicAdmissionRequirements: Yup.string(),
  tuitionAmounts: Yup.string(),
  scholarshipsOffered: Yup.boolean(),
  scholarshipsOfferedText: Yup.string(),
  organizationType: Yup.string(),
  esportsTitles: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      logo: Yup.string(),
    })
  ),
});

const isPublicValidation = Yup.boolean().test(
  "isPublic-name-check",
  "Please provide a team name.",
  function (value) {
    const { name } = this.parent;
    if (value && !name) {
      return this.createError({
        path: "isPublic",
        message: "Please provide a team name.",
      });
    }
    return true;
  }
);

const TeamAccountValidationSchema = Yup.object().shape({
  photoUrl: Yup.string(),
  name: Yup.string(),
  description: Yup.string(),
  announcement: Yup.string(),
  locationCity: Yup.string(),
  locationState: Yup.string(),
  locationCountryCode: Yup.string(),
  locationZipCode: Yup.string().test(
    "is-valid-zipcode",
    "Invalid ZIP code",
    function (value) {
      // If the value is empty, consider it valid
      if (!value) {
        return true;
      }
      // Check if the value matches the ZIP code format
      return /^\d{5}(-\d{4})?$/.test(value);
    }
  ),
  socials: Yup.object().shape({
    instagram: containsKeyword("instagram"),
    twitter: containsKeyword("x"),
    facebook: containsKeyword("facebook"),
    threads: containsKeyword("threads"),
  }),
  gamesPlayedByTeam: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
    })
  ),
  esportsTitles: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      logo: Yup.string(),
    })
  ),
  isPublic: isPublicValidation,
  featuredOnProfile: Yup.boolean(),
  privateNote: Yup.string(),
});

const SquadValidationSchema = Yup.object().shape({
  name: Yup.string().required("Squad name is required."),
  description: Yup.string(),
});

const AddtoSquadValidation = Yup.object().shape({
  selectedSquads: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        id: Yup.string().required(),
      })
    )
    .min(1, "You must select at least one squad."), // Ensure at least 1 squad is selected
});

const AddToTeamInviteValidation = Yup.object().shape({
  selectedTeam: Yup.string().required("You must select a team."), // Ensure at least 1 team is selected
});
export const Validation = {
  LoginSchema,
  RegisterSchema,
  RegisterViaInviteSchema,
  PlayerAccountValidationSchema,
  OrganizationAccountValidationSchema,
  TeamAccountValidationSchema,
  SquadValidationSchema,
  AddtoSquadValidation,
  AddToTeamInviteValidation,
  ManagePlayerValidationSchema,
};
